import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import Pulse from '@ubisend/pulse-boost';

Pulse.render({
  partner: {
    logo:
      'https://s3.eu-west-2.amazonaws.com/ubisend.website/assets/static/images/logo.svg',
    url: 'https://www.ubisend.com/',
    policy_name: 'By creating my account I agree to the ubisend End User License Agreement.',
    policy_url: 'https://s3.eu-west-2.amazonaws.com/ubisend.legals/ubisend-EULA.pdf'
  }
});
